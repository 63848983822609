import { Button, Typography } from '@mui/material'
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import duration from 'dayjs/plugin/duration';
import { useRouter } from 'next/router';
import { setTimeout } from 'timers';
import Grid from "@mui/material/Unstable_Grid2";
dayjs.extend(duration);

export default function countdownTime({ campaign, alignment }: any) {

  const campaignEnd = campaign?.campaign_end as any
  const [timeRemaining, setTimeRemaining] = useState<{ hours: number; minutes: number; seconds: number }>({ hours: 0, minutes: 0, seconds: 0 });
  const [endTime, setEndTime] = useState<Date | null>(dayjs(campaignEnd).toDate());
  const [statusCampaignEnd, setStatusCampaignEnd] = useState(false)
  const now = new Date();
  const router = useRouter();


  const calculateTimeRemaining = (endTime: Date): { hours: number; minutes: number; seconds: number } => {
    const now = new Date().getTime();
    const distance = endTime.getTime() - now;
    if (distance <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }
    const hours = Math.floor(distance / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return { hours, minutes, seconds };
  };



  useEffect(() => {
    if (!endTime) return;
    if (campaign !== null) {
      if (endTime <= now) {
        setStatusCampaignEnd(false)
        setTimeRemaining({ hours: 0, minutes: 0, seconds: 0 });
        return;
      }
      const intervalId = setInterval(() => {
        const remaining = calculateTimeRemaining(endTime);
        setTimeRemaining(remaining);
        // setRemaining(remaining)
        if (remaining.hours <= 0 && remaining.minutes <= 0 && remaining.seconds <= 0) {
          clearInterval(intervalId);
          setStatusCampaignEnd(true)
          sessionStorage.setItem('hasReloaded', 'true');
          // router.reload();
          clearInterval(intervalId);
            // window.location.href = router.route;
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }else{
      setStatusCampaignEnd(true)
    }
  }, [endTime, router]);

  return (
    
    <>
        {statusCampaignEnd === false && (
          <Grid container sx={{
            gap: "4px",
            float: { 
              lg: `${alignment}`, md: "right", sm: "right"
            },
            display: {
              xs: 'inline-flex'
            },
            justifyItems: "center",
            alignContent: "center"
          }}>
            <Typography 
              sx={{
                mr: 1,
                fontSize:{ xs:"14px", md:"16px" },
                paddingTop: { xs: "5px", md: "10px" },
              }}>
              หมดเวลาใน
            </Typography>
            <Grid>
              <Button
                variant="contained"
                sx={{
                  fontSize: '12px',
                  padding: '4px',
                  borderRadius: '6px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  minWidth: "35px",
                  borderColor: "#DB5A00",
                  bgcolor: "#DB5A00",
                  '&:hover': {
                    borderColor: "#b34a00",
                    bgcolor: "#b34a00"
                  },
                  display: { 
                    xs: "inline-block", sm: "flex", md: "flex", lg: "flex"
                  },
                  color: "#ffffff"
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    pr: "2px",
                    display: { xs: "inline-block" }
                  }}

                >{timeRemaining.hours}</Typography>
                <Typography
                  sx={{
                    fontSize: "10px",
                    display: {
                      xs: "inline-block"
                    }
                  }}
                >ชั่วโมง</Typography>
              </Button>
            </Grid>
            <Typography sx={{ mt: { xs: "3px", md: "10px" } }}>
              :
            </Typography>
            <Grid>
              <Button
                variant="contained"
                sx={{
                  fontSize: '12px',
                  padding: '4px 4px',
                  borderRadius: '6px',
                  display: { 
                    xs: "inline-block", sm: "flex", md: "flex", lg: "flex"
                  },
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  minWidth: "35px",
                  borderColor: "#DB5A00",
                  bgcolor: "#DB5A00",
                  '&:hover': {
                    borderColor: "#b34a00",
                    bgcolor: "#b34a00"
                  },

                  color: "#ffffff"
                }}
              >
                <Typography sx={{
                    fontSize: "16px",
                    pr: "2px",
                    display: { xs: "inline-block" }
                  }}
                >{timeRemaining.minutes}</Typography>
                <Typography sx={{
                  fontSize: "10px",
                  display: {
                    xs: "inline-block"
                  }
                }}
                >นาที</Typography>
              </Button>
            </Grid>
            <Typography sx={{ mt: { xs: "3px", md: "10px" } }}>
              :
            </Typography>
            <Grid>
              <Button
                variant="contained"
                sx={{
                  fontSize: '12px',
                  padding: '4px 4px',
                  borderRadius: '6px',
                  display: { 
                    xs: "inline-block", sm: "flex", md: "flex", lg: "flex"
                  },
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  minWidth: "35px",
                  borderColor: "#DB5A00",
                  bgcolor: "#DB5A00",
                  '&:hover': {
                    borderColor: "#b34a00",
                    bgcolor: "#b34a00"
                  },

                  color: "#ffffff"
                }}
              >
                <Typography sx={{
                    fontSize: "16px",
                    pr: "2px",
                    display: { xs: "inline-block" }
                  }}
                >{timeRemaining.seconds}</Typography>
                <Typography sx={{
                  fontSize: "10px",
                  display: {
                    xs: "inline-block"
                  }
                }}
                >วินาที</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
    </>
  )
}