import { ImgUrl } from '@/config';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Card, CardContent, Typography, Stack, CardActions, Rating, Grid } from '@mui/material';
import React, { useRef } from 'react'
import Link from "next/link";
import Image from "next/image";
import router from 'next/router';

type Props = {}

export default function ProductCampaignStart({
  data
}: any) {
 

  const product = data?.product
 
  const scrollContainer = useRef(null);
  const handleScroll = (direction: string, scrollContainer: React.RefObject<HTMLDivElement>) => {
    if (scrollContainer?.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current;


      const scrollAmount = clientWidth;
      const maxScrollLeft = scrollWidth - clientWidth;

      if (direction === 'left') {
        scrollContainer.current.scrollBy({
          left: -scrollAmount,
          behavior: 'smooth',
        });
      } else if (direction === 'right') {
        if (scrollLeft + clientWidth >= scrollWidth) {

          scrollContainer.current.scrollTo({
            left: 0,
            behavior: 'auto',
          });
        }

        scrollContainer.current.scrollBy({
          left: scrollAmount,
          behavior: 'smooth',
        });
      }
    }
  };

  const formatPrice = (price: number, digits: number = 2): number => {
    return parseFloat(price.toFixed(digits));
  };

  const itemList = {
    itemListId: "campaigned_products",
    itemListName: "สินค้า/บริการ แคมเปญ",
  };

  const handleProductClick = (item: any, itemList: { itemListId: string, itemListName: string }): void => {
    if (!item) {
      console.warn("No product data available.");
      return;
    }


    const {
      product_id,
      product_title,
      product_slug,
      store_id,
      product_discounted_price,
      product_normal_price
    } = item;

    const isDiscounted = product_discounted_price > 0;
    const price = formatPrice(isDiscounted ? product_discounted_price : product_normal_price) ?? null;
    const discount = formatPrice(isDiscounted ? product_normal_price - product_discounted_price : 0) ?? null;

    // Create the product details object, defaulting to null for missing values
    const productDetails = {
      item_id: product_id ?? null,
      item_name: product_title ?? null,
      affiliation: item.affiliation ?? null,
      coupon: item.coupon ?? null,
      discount,
      index: item.index ?? null,
      item_brand: item.item_brand ?? null,
      item_category: item.item_category ?? null,
      item_category2: item.item_category2 ?? null,
      item_list_id: itemList.itemListId ?? null,
      item_list_name: itemList.itemListName ?? null,
      item_variant: item.item_variant ?? null,
      location_id: item.location_id ?? null,
      price,
      quantity: 1,  // Default quantity to 1 or adjust as needed
    };

    // Update dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce data
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        item_list_id: productDetails.item_list_id,
        item_list_name: productDetails.item_list_name,
        items: [productDetails],
      },
    });

    console.log("DataLayer updated:", {
      event: "select_item",
      ecommerce: {
        item_list_id: productDetails.item_list_id,
        item_list_name: productDetails.item_list_name,
        items: [productDetails],
      },
    });

    // Navigate to the product page
    router.push({
      pathname: `/product/${product_slug}`,
      query: { store: store_id, id: product_id },
    });
  };
  return (
    <Grid container sx={{mt:"10px"}}>
      <Box sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}>
        <IconButton
          onClick={() => handleScroll('left', scrollContainer)}
          disableFocusRipple
          disableRipple
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            fontSize: {
              xs: "14px",
              md: "12px"
            },

            zIndex: 1,
            bgcolor: "#FFFFFF",
            borderRadius: '4px',
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </IconButton>

        <Box sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          p: "6px",
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }} ref={scrollContainer}>
          {product?.map((item: any, idx: number) => (
            <>
              {/* <Link
                href={{
                  pathname: `/product/${item?.product_slug}`,
                  query: { store: item?.store_id, id: item?.product_id },
                }}
              > */}
              <div
                key={item.product_id}
                onClick={() => handleProductClick(item, itemList)}
                style={{ cursor: 'pointer' }}
              >
                <Card
                  key={idx}
                  className="product-card-best-moblie"
                  sx={{
                    flex: '0 0 auto',
                    width: 150,
                    marginRight: 2,
                    height: 290,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',

                    borderRadius: "14px",
                    paddingBottom: 1,
                  }}
                >
                  <Box sx={{ textAlign: "center", position: "relative", width: '100%' }}>
                    <Box sx={{ width: '100%', paddingBottom: '100%', position: 'relative' }}>
                      <Image
                        src={item?.product_image[0]?.image_url
                          ? `${ImgUrl?.imageCampaign}/uploads/member/${item?.memberId}/store/product/${item?.product_image[0]?.image_url}`
                          : "/assets/images/default/maaboom_empty.webp"}
                        alt={item.product_title as string || 'default'}
                        fill
                        priority

                        style={{ objectFit: 'cover' }}
                      />
                    </Box>
                    {
                      item.product_in_campaign === null ? (
                        <></>
                      ) : (
                        <img
                            src={data?.frame_product_full_url}
                          alt="Frame Product"
                          style={{
                            position: "absolute",
                            zIndex: 9,
                            top: "10px",
                            left: "0px",
                            width: "100%",
                          }}
                        />
                      )
                    }

                  </Box>
                  <CardContent sx={{ height: "45px" }}>
                    <Typography
                      sx={{
                        mt: "10px",
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 2,
                        whiteSpace: 'normal',
                        color: "#717171",
                        fontSize: "14px",
                        lineHeight: "20px"
                      }}
                    >
                      {item.product_title}
                    </Typography>
                    <Stack direction={"row"}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                        className="txt-bold"
                        gutterBottom
                        color="secondary"
                      >
                        ฿{item?.product_discount_price}
                      </Typography>
                      <Box
                        sx={{
                          fontSize: "10px",
                          textDecoration: "line-through",
                          pl: 1,
                          mt: 0.7,
                          fontWeight: "bold",
                          lineHeight: "14px",
                          color: "#AEAEAE",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        {item.product_discounted_price === 0
                          ? ''
                          : `฿${item.product_normal_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                      </Box>
                    </Stack>

                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      pl: "8px",
                      pt: "8px",

                      pr: "8px"
                    }}
                  >
                    <Box display="inline-flex" sx={{ alignItems: "center" }}>
                      <Rating
                        name="read-only"
                        value={item?.product_score ?? 0}
                        precision={0.5}
                        readOnly
                        size="small"
                        sx={{ fontSize: 10 }}
                      />
                      <Typography
                        variant="caption"
                        sx={{ mr: 1, ml: 1, fontSize: 10 }}
                      >
                        {item.product_score ?? 0}
                      </Typography>
                    </Box>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ fontSize: 8 }}
                    >
                      ขายแล้ว {item.product_sold} ชิ้น
                    </Typography>
                  </CardActions>
                </Card>
                </div>
              {/* </Link> */}

            </>
          ))}
        </Box>

        <IconButton

          onClick={() => handleScroll('right', scrollContainer)}
          disableFocusRipple
          disableRipple
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            fontSize: {
              xs: "14px",
              md: "12px"
            },
            zIndex: 1,
            bgcolor: "#FFFFFF",
            borderRadius: '4px',
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </IconButton>
      </Box>
    </Grid>
  )
}